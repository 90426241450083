
import ConstUtils from '@/utils/ConstUtils.js';
import MapUtils from '@/utils/MapUtils.js';

export default class UserEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter) {
    var event = UserEvents.List.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  static Save = {
    Names: {
      Request: "UserSaveRequestEvent",
      Response: "UserSaveResponseEvent",
    },
    Errors: {
      
    },
    Request: function(modifiedUser, modifiedEmployee) {
      var event = {
          handler: "UserSaveRequestEvent",
          eventName: "UserSaveRequestEvent",
          modifiedUserId: modifiedUser[ConstUtils.FIELDS.ID],
          modifiedEmployeeId: modifiedEmployee[ConstUtils.FIELDS.ID],
          User: modifiedUser,
          Employee: modifiedEmployee,
      }
      return event;
    }
  }
  
  resetPassword(email) {
    var event = UserEvents.ResetPassword.Request(email);
    return this.eventsDomain.initRequest(event);
  }

  static ResetPassword = {
    Names: {
      Request: 'CredentialsLostRequestEvent',
      Response: 'CredentialsLostResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(email) {
      const event = {
          handler: "CredentialsLostRequestEvent",
          eventName: "CredentialsLostRequestEvent",
      };
      event["email"] = email;
      return event;
    },
  }

  static SignOut = {
    Names: {
      Request: 'UserSignOutRequestEvent',
      Response: 'UserSignOutResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function() {
      const event = {
          handler: "UserSignOutRequestEvent",
          eventName: "UserSignOutRequestEvent",
      };
      return event;
    },
  }
  
  static Invite = {
      Names: {
        Request: 'UserInviteRequestEvent',
        Response: 'UserInviteResponseEvent',
      },

      Errors: {
        InviteExists: {
          id: 'InviteExists',
          msg: 'A user with that email already exists',
          details: ''
        },
      },

      Request: function(kvps) {
        var event = {
              handler: "UserInviteRequestEvent",
              eventName: "UserInviteRequestEvent",
            };
        event = MapUtils.updateMap(kvps, event);
        return event;
      },
    }
    
  static SignUp = {
      Names: {
        Response: 'UserSignUpResponseEvent',
        Request: 'UserSignUpRequestEvent',
      },

      Errors: {
        find: function(error) {
          if (this.EmailExists.messageId === error.messageId) {
            return UserEvents.SignUp.Errors.EmailExists;
          }
          if (this.CompanyExists.messageId === error.messageId) {
            return UserEvents.SignUp.Errors.CompanyExists;
          }
          return error;
        },
        EmailExists: {
          id: 'EmailExists',
          msg: 'A user with that email already exists',
          details: '',
          isEmailExists: function() {
            return true;
          },
          isCompanyExists: function() {
            return false;
          },
        },
        CompanyExists: {
          id: 'CompanyExists',
          msg: 'A company with that website already exists',
          details: '',
          isEmailExists: function() {
            return false;
          },
          isCompanyExists: function() {
            return true;
          },
        },
      },
      
      Fields: {
        FIRST: 'userFirst',
        LAST: 'userLast',
        EMAIL: 'userEmail',
        PHONE: 'userPhone',
        COMPANY_NAME: 'companyName',
        COMPANY_CITY: 'companyCity',
        COMPANY_PROVINCE: 'companyProvince',
        ROLE: 'companyRole',
        CODE: 'code',
        OTHER: 'other',
        TERMS: 'terms',
        FEATURE_CHECK_IN: 'featureCheckIn',
        FEATURE_HOURS_OF_SERVICE: 'featureHoursOfService',
        FEATURE_INSPECTION: 'featureInspection',
        ADS: 'ads',
        FLEET_SIZE: 'fleetSize',
      },
      
      Request: function(kvps) {
        var event = {
          handler: 'UserSignUpRequestEvent',
          eventName: 'UserSignUpRequestEvent',
        }
        event = MapUtils.updateMap(kvps, event);
        return event;
      },
      
    }

    static SignIn = {
      Names: {
        Request: 'UserSignInRequestEvent',
        Response: 'UserSignInResponseEvent',
      },

      Errors: {
        find: function(error) {
          if (this.NotFound.messageId === error.messageId) {
            return UserEvents.SignIn.Errors.NotFound;
          }
          if (this.Unauthorized.messageId === error.messageId) {
            return UserEvents.SignIn.Errors.Unauthorized;
          }
          return error;
        },
        
        NotFound: {
          messageId: 'UserNotFound',
          hint: 'The specified username and password combination do not exist',
          details: '',
        },
        Unauthorized: {
          messageId: 'UnauthorizedException',
          hint: 'You do not have permission to access the portal',
          details: '',
        }
      },

      Request: function(email, password, company) {
        var event = {
          email: email,
          password: password,
          handler: 'UserSignInRequestEvent',
          eventName: 'UserSignInRequestEvent',
        }
        if (company) {
          event['Company'] = company;
        }
        return event;
      },
    }
}